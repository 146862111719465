import React from "react";

function WinningValues({winningValuesData}) {
  return (
    <>
      <div className="col-12 col-md-12 col-lg-4">
        <div className={`box daobox ${winningValuesData.classes && winningValuesData.classes}`}>
          <h3>{winningValuesData.header}</h3>
          <p>
            {winningValuesData.body}
          </p>
        </div>
      </div>
    </>
  );
}

export default WinningValues;

import React from "react";
import ReactHtmlParser from 'react-html-parser'; 

function TeamMember({teamMember}) {
  return (
    <>
      <div className={`col-12 col-sm-6 col-md-4 col-lg-4 mb80`}>
        <div className="teamMember">
          <div className={`avatar border${teamMember.borderType}`}>
            <img src={`${teamMember.image}`} className="img-fluid" />
          </div>
          <h4>{teamMember.bold_text}</h4>
          <h5>
            {teamMember.normal_text} <span>{teamMember.colored_text}</span>
            {/* TODO make the second color yellow */}
          </h5>
          <p>
            {teamMember.explain}
          </p>
        </div>
      </div>
    </>
  );
}

export default TeamMember;

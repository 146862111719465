import React, { Component } from 'react'
import butter from '../../butter-client'
import BlogViewItem from "../../components/BlogViewItem/BlogViewItem";

class Blog extends Component {
  state = {
    meta: {},
    data: []
  }

  async componentDidMount () {
    const resp = await butter.post.list({ page: 1, page_size: 18 })
    this.setState(resp.data)
  }

  render () {
    return (
      <div>
        <div className="daoheroBanner">
          <div className="daoellipse">
            <svg xmlns="http://www.w3.org/2000/svg" width="1065" height="359" viewBox="0 0 1065 359" fill="none">
                <circle cx="129.5" cy="129.5" r="129.5" fill="#FDC307"/>
                <circle cx="586" cy="101" r="86" fill="#FDC307"/>
                <circle cx="892" cy="156" r="123" fill="#FDC307"/>
            </svg>
          </div>
          <div className="container containerCustom">
            <div className="daoheroBannerContent">
              <h1>DAOversity</h1>
              <h2>Thoughts from FTW.DAO</h2>
            </div>
          </div>
        </div>

        <div className="middlePart">
          <div className="middleContent">
            <div className="container containerCustom">


              <div className="gridPosts">
                {this.state.data.map((post, key) => {
                  return (
                      <BlogViewItem blog={post}/>
                  )
                })}
              </div>

              <hr style={{ clear: "both", marginTop: "40px" }} />

              <iframe width="100%" height="320"
                src="https://ftwventures.substack.com/embed"
                frameborder="0" scrolling="no"
              />


            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Blog

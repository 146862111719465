import React from "react";
import { NavLink } from "react-router-dom";

function FtWinnersPart(props) {
  return (
    <>
      <div className={props.classes}>
        {props.heading && <h1>{props.heading}</h1>}
        <div className="col-12 col-md-12 col-lg-6 leftPart posRelative">
          <h1 className="mb-4">
            FTW DAO NFT
          </h1>
          <h4 className="mb-4">
            Funding the 2030 we all want to work and live in
          </h4>
          <p className="mb-4">
            Membership card holders become FTW DAO community members and can vote on how our ecosystem funds are
            deployed towards our shared mission.
          </p>
          <div className="btnPart mt-5">
            <NavLink to='/nft' className="btn btnWhite">
            Reserve a Mint Pass  
            </NavLink>
            <NavLink to="/membership" className="btn btntransparentBordered">
              Learn more
            </NavLink>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-6 px-0 rightPart posRelative">
          {/* <img src="images/winnerPic.png" className="img-fluid winnerPic" /> */}
          {/* <img src="images/winnerPic.png" srcset="images/winnerPic1x.png 1x, images/winnerPic2x.png 2x, images/winnerPic3x.png 3x" className="img-fluid winnerPic"  /> */}
            <picture className="winnerPic">
                <source media="(min-width:992px)" srcset="images/winnerPic_Gina.png"></source>
                <source media="(min-width:650px)" srcset="images/winnerPic_Gina2x.png"></source>
                <source media="(min-width:320px)" srcset="images/winnerPic_Gina1x.png"></source>
                <img src="images/winnerPic_Gina.png" className="img-fluid" />
            </picture>
          <div className="mintNow">
            {
              props.image ? 
              (
                <>
                <img src="images/starGray.png" className="img-fluid star" />
                <img src="images/mintNowGray.png" className="img-fluid" id="rotation" />
              </>
              )
              :
              <>
                <img src="images/star.png" className="img-fluid star" />
                <img src="images/mintNow.png" className="img-fluid" id="rotation" />
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default FtWinnersPart;

import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Accordion } from "react-bootstrap";

function FAQs({ faqsData }) {
  return (
    <>

      {
        <Accordion.Item eventKey={faqsData.Id} className="card cardCustom">
          <Accordion.Header className={`card-header ${faqsData.bgColor && faqsData.bgColor}`}>
            <h5>{faqsData.header}</h5>
          </Accordion.Header>
          <Accordion.Body className="card-body">
            <p>{ReactHtmlParser(faqsData.body)}</p>
          </Accordion.Body>
        </Accordion.Item>
      }

    </>
  );
}

export default FAQs;

import React from "react";
import ReactHtmlParser from 'react-html-parser';

function PartnerCard({ infoCardData }) {
    const Heading = `h3`;
    return (
        <>
            {
                infoCardData.divData &&
                <div className={infoCardData.divClass}>{ReactHtmlParser(infoCardData.divData)}</div>
            }
            {
                infoCardData.image && (
                    <div className={"logo border1"}>
                            <img src={infoCardData.image} className="img-fluid" alt="partner"/>
                    </div>
                )
            }
            <Heading className="highlight">{infoCardData.heading}</Heading>
            <p>{ReactHtmlParser(infoCardData.description)}</p>
        </>
    );
}

export default PartnerCard;

import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
} from "react-share";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import RedditIcon from '@material-ui/icons/Reddit';
import EmailIcon from '@material-ui/icons/Email';
import DiscordIcon from './discord.png';
import axios from 'axios';
import {Link} from "react-router-dom";


function SocialShare({url, title}) {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = useState(false);
    const shareMessage = async (message) => {
        setLoading(true);
        try {
            const response = await axios.post('https://discordapp.com/api/webhooks/YOUR_WEBHOOK_ID/YOUR_WEBHOOK_TOKEN', {
                content: message,
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };


    return (
        <div>
            <button className="share-blog-button" onClick={handleClickOpen}>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="#000" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 10.0215L14 7.02148L11 4.02148" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path
                        d="M12 14.0215H2.5C2.36739 14.0215 2.24021 13.9688 2.14645 13.875C2.05268 13.7813 2 13.6541 2 13.5215V6.02148"
                        stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M4.6875 11.5215C5.02114 10.2336 5.77295 9.0929 6.82495 8.27845C7.87694 7.464 9.16958 7.02189 10.5 7.02148H14"
                        stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>

            <Dialog
                className="share-blog-dialog"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <h3> Share this blog</h3>

                <div className="share-blog-container">
                    <TwitterShareButton url={url} title={title}>
                        <TwitterIcon fontSize="large"/>
                    </TwitterShareButton>
                    <LinkedinShareButton url={url} title={title}>
                        <LinkedInIcon fontSize="large"/>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={url} title={title}>
                        <WhatsAppIcon fontSize="large"/>
                    </WhatsappShareButton>
                    <EmailShareButton url={url} subject={title}>
                        <EmailIcon fontSize="large"/>
                    </EmailShareButton>
                    <FacebookShareButton url={url} quote={title}>
                        <FacebookIcon fontSize="large"/>
                    </FacebookShareButton>
                    <TelegramShareButton url={url} title={title}>
                        <TelegramIcon fontSize="large"/>
                    </TelegramShareButton>
                    <RedditShareButton url={url} title={title}>
                        <RedditIcon fontSize="large"/>
                    </RedditShareButton>
                    {/*<button onClick={shareMessage} disabled={loading}>*/}
                    {/*    {loading ? (*/}
                    {/*        'Sharing...'*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <img src={DiscordIcon} style={{width: '25px', height: '25px'}}/>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</button>*/}

                </div>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SocialShare;

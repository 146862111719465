import React from "react";

function RoadmapTimeline({ timelineData }) {
    return (
        <>
            {timelineData.heading1 && <h2>{timelineData.heading1}</h2>}
            {timelineData.heading2 && (
                <h3>{timelineData.heading2}</h3>
            )}
            {timelineData.description && (
                <p>
                    {timelineData.description}
                </p>
            )}
        </>
    );
}

export default RoadmapTimeline;

import React from 'react'
import ReactHtmlParser from 'react-html-parser'; 

function MemberCard({membershipData}) {
  return (
    <>
    <div className="col-12 col-sm-12 col-md-12 col-lg-4 part">
        <div className="heading">
          {membershipData.heading}
          <div className="findOne">
            {membershipData.membershipCount}
          </div>
          <div className='SubHead'>
            {membershipData.Subheading}
          </div>
          {membershipData.nft && (
            <div className='nftpreview-mini'>
              <img src={membershipData.nft} alt="nft" />
            </div>
          )}
        </div>
        {membershipData.level && <p>{membershipData.level}</p>}
        <h4 className="mt-3">Access</h4>
        <p>{ReactHtmlParser(membershipData.access)}</p>
        <h4 className="mt-3">Benefits</h4>
        <p>{ReactHtmlParser(membershipData.benefits)}</p>
    </div>
    </>
  )
}

export default MemberCard;

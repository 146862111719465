import React, {useEffect, useState} from "react";
import InfoCard from "../../components/InfoCard/InfoCard";
import BlogViewItem from "../../components/BlogViewItem/BlogViewItem";
import {NavLink} from "react-router-dom";
import butter from '../../butter-client'
import butterClient from "../../butter-client";

function Home() {
    const [pageData, setPageData] = useState();
    const [topBlogs, setTopBlogs] = useState();

    useEffect(() => {
        const fetchPageContent = async () => {
            const data = await butterClient.page.retrieve('*', 'landing-page-v2')
            const fieldData = data.data.data.fields;
            setPageData(fieldData);

            const top = [];
            const slugs = fieldData.three_blog_posts;
            const slugsToShow = [
                slugs['blog_slug_1'],
                slugs['blog_slug_2'],
                slugs['blog_slug_3']
            ];

            const fetchBlogs = async () => {
                const blogList = await butter.post.list({page: 1, page_size: 18});
                blogList.data.data.forEach(blog => {
                    if (slugsToShow.includes(blog.slug)) {
                        top.push(blog);
                    }
                })
                setTopBlogs(top)
            }

            fetchBlogs().then(res => {

            });
        }

        fetchPageContent().then(res => {
            console.log(res)
        });
    }, [])


    if (pageData) {
        return (
            <div>
                <div className="heroBanner">
                    <div className="container containerCustom">

                        {/** Landing, logos and figures */}
                        <div className="heroBannerContent">
                            <div className="col-12 px-0 landing">
                                <h1>{pageData.landing_page_header.page_title}</h1>
                                <br></br>
                                <h3>{pageData.landing_page_header.page_subtitle}</h3>
                                <br></br>
                                <div className="landing-action-group">
                                    <NavLink to="/dao" className="btn btnWhite btnBodr">
                                        {pageData.landing_page_header.left_side_button}
                                    </NavLink><br/>
                                    <NavLink to="/membership" className="btn btnTheme btnBodr">
                                        {pageData.landing_page_header.right_side_button}
                                    </NavLink><br/>
                                </div>
                                <div className="dao-figures-group">
                                    <div className="figure">
                                        <p>{pageData.landing_page_header.figure_1}</p>
                                        <p>{pageData.landing_page_header.figure_1_text}</p>
                                    </div>
                                    <div className="figure">
                                        <p>{pageData.landing_page_header.figure_2}</p>
                                        <p>{pageData.landing_page_header.figure_2_text}</p>
                                    </div>
                                    <div className="figure">
                                        <p>{pageData.landing_page_header.figure_3}</p>
                                        <p>{pageData.landing_page_header.figure_3_text}</p>
                                    </div>
                                </div>
                                <div className="logos-group">
                                    <h3>{pageData.landing_page_header.logo_intro}</h3>
                                    {
                                        pageData.landing_page_header.logos.map((logo, index) => {
                                            return ([<img key={index} src={logo.logo} alt=""/>])
                                        })
                                    }
                                </div>
                            </div>
                        </div>


                        {/** Did you know section */}
                        <div className="px-0 some-facts">
                            <img src="/images/svg-did-you-know.svg" alt="" style={{position: 'absolute'}}/>
                            <h1>{pageData.four_box_container.header}</h1>
                            <div className="boxes">
                                <div className="box">{pageData.four_box_container.top_left}</div>
                                <div className="box">{pageData.four_box_container.top_right}</div>
                                <div className="box">{pageData.four_box_container.bottom_left}</div>
                                <div className="box">{pageData.four_box_container.bottom_right}</div>
                            </div>
                        </div>

                        {/** Despite all section */}
                        <div className="col-12 px-0 facts">
                            <h1>{pageData.four_values_percentage_box.header}</h1>
                            <div className="boxes">
                                <div className="box">
                                    <p>{pageData.four_values_percentage_box.top_left_value}%</p>
                                    <p>{pageData.four_values_percentage_box.top_left_text}</p>
                                </div>
                                <div className="box">
                                    <p>{pageData.four_values_percentage_box.top_right_value}%</p>
                                    <p>{pageData.four_values_percentage_box.top_right_text}</p>
                                </div>
                                <div className="box">
                                    <p>{pageData.four_values_percentage_box.bottom_left_value}%</p>
                                    <p>{pageData.four_values_percentage_box.bottom_left_text}</p>
                                </div>
                                <div className="box">
                                    <p>{pageData.four_values_percentage_box.bottom_right_value}%</p>
                                    <p>{pageData.four_values_percentage_box.bottom_right_text}</p>
                                </div>
                            </div>
                            <div className="facts-text">
                                <p>{pageData.four_values_percentage_box.subtext_1}</p>
                                <p>{pageData.four_values_percentage_box.subtext_2}</p>
                            </div>
                        </div>

                        {/** Meet FTW DAO */}
                        <div className="col-12 px-0 meet-ftw">
                            <div className="dots">...</div>
                            <h2>{pageData.meet_ftw_dao.section_header}</h2>
                            <div className="dash">|</div>
                            <h1>{pageData.meet_ftw_dao.header}<span
                                className="highlight">{pageData.meet_ftw_dao.header_colorized}</span></h1>

                            <p className="meet-ftw-card-header">{pageData.meet_ftw_dao.sub_header}</p>

                            <img src={pageData.meet_ftw_dao.ftw_image} alt="" width={'60%'}/>

                            <div className="meet-ftw-content">
                                <p>{pageData.meet_ftw_dao.catch_phrase_start} <span
                                    className="emphasize bright">{pageData.meet_ftw_dao.catch_phrase_emphasized_text_orange}</span> {pageData.meet_ftw_dao.catch_phrase_middle}
                                    <span
                                        className="emphasize dark">{pageData.meet_ftw_dao.catch_phrase_emphasized_text_black}</span><br/>
                                    {pageData.meet_ftw_dao.catch_phrase_end}</p>

                                <p>{pageData.meet_ftw_dao.text_1}</p>

                                <p>{pageData.meet_ftw_dao.text_2}</p>
                            </div>
                            <NavLink to="/nft"
                                     className="btn btnTheme">{pageData.meet_ftw_dao.button_text}</NavLink><br/>
                        </div>

                        {/** Quotes */}
                        {pageData.quote_sayer.quote.map((quote, index) => {
                            return ([
                                <div className="quote-board-container">
                                    <div className={index % 2 === 0 ? 'quote-board' : 'quote-board reverse'}>
                                        <img className="quote-icon-top" src="quote-icon.svg" alt="" width={48}/>
                                        <div className="quote-content-area">
                                            <p>{quote.quote}</p>
                                            <img className="quote-icon-bottom" src="quote-icon.svg" alt="" width={48}/>
                                            <p>{quote.quote_author} - <span
                                                className="quote-member-time"> {quote.member_since}</span></p>
                                        </div>
                                        <div className="quote-border">
                                            <img className="quote-author-image" src={quote.quote_author_image} alt=""
                                                 width={128}/>
                                        </div>
                                    </div>
                                </div>
                            ])
                        })
                        }
                    </div>
                </div>
                <div className="middlePart">
                    <div className="middleContent">
                        <div className="container containerCustom">

                            {/*Will move to another page*/}
                            {/*<FtWinnersPart classes="ftWinnersPart" />*/}

                            {/* Member Benefits */}
                            <div className="mintPassBenefitPart">
                                <h1>{pageData.member_benefits.header}</h1>
                                <h2>{pageData.member_benefits.subheader}</h2>
                                <p>{pageData.member_benefits.sub_sub_header}</p>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className={`box bgWhite`}>
                                            <InfoCard
                                                image={pageData.member_benefits.image_1}
                                                header={pageData.member_benefits.header_1}
                                                description={pageData.member_benefits.text_1}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className={`box bgYellow`}>
                                            <InfoCard
                                                image={pageData.member_benefits.image_2}
                                                header={pageData.member_benefits.header_2}
                                                description={pageData.member_benefits.text_4}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className={`box bgYellow`}>
                                            <InfoCard
                                                image={pageData.member_benefits.image_3}
                                                header={pageData.member_benefits.header_3}
                                                description={pageData.member_benefits.text_3}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className={`box bgWhite`}>
                                            <InfoCard
                                                image={pageData.member_benefits.image_4}
                                                header={pageData.member_benefits.header_4}
                                                description={pageData.member_benefits.text_4}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="join-dao">
                                    <NavLink to="/nft" className="btn btnTheme">
                                        {pageData.member_benefits.button_text}
                                    </NavLink><br/>
                                </div>
                            </div>

                            <div className="roadmapPart">
                                <h1>{pageData.launch_roadmap.header}</h1>
                                <div className="roadmap">
                                    <div className="timeline">
                                        <div className="events">
                                            <ol>
                                                <ul>
                                                    <li>
                                                        <a className="selected"></a>
                                                    </li>
                                                    <li>
                                                        <a></a>
                                                    </li>
                                                    <li>
                                                        <a></a>
                                                    </li>
                                                </ul>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="events-content">
                                        <ol>
                                            <li id="0" className="selected">
                                                <h2>{pageData.launch_roadmap.live_step_header}</h2>
                                                <p>{pageData.launch_roadmap.live_step_text}</p>
                                            </li>

                                            <li id="1">
                                                <h2>{pageData.launch_roadmap.second_step_header}</h2>
                                                <p>{pageData.launch_roadmap.second_step_text}</p>
                                            </li>

                                            <li id="2">
                                                <h2>{pageData.launch_roadmap.third_step_header}</h2>
                                                <p>{pageData.launch_roadmap.third_step_text}</p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <br></br>
                                <NavLink to='/nft' className="btn btnTheme" style={{'marginBottom': '2rem'}}>
                                    {pageData.launch_roadmap.button_text}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="middleBottom">
                        <svg
                            width="1280"
                            height="720"
                            viewBox="0 0 1280 720"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_178_7)">
                                <rect width="1280" height="720" fill="#000"/>
                                <circle cx="640" cy="-880" r="1600" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_178_7">
                                    <rect width="1280" height="720" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="middleLastPart black">
                        <div className="container containerCustom">
                            <h1>
                                <span>DAO</span>versifying the<br/>venture ecosystem
                            </h1>
                            <div className="gridPosts">
                                {topBlogs && topBlogs.map((blog, index) => {
                                    return (
                                        <div key={index}>
                                            <BlogViewItem blog={blog}/>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="text-center mt-5">
                                <NavLink to='/dao' className="btn btnWhite">
                                    Learn about the DAO
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>
    }
}

export default Home;

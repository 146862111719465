import React from "react";
import ReactHtmlParser from 'react-html-parser';

function InfoCard({image, header, description}) {
    const Heading = `h2`;
    return (
        <div className="info-card">
            <img src={image} className="img-fluid" alt=""/>
            {<Heading>{header}</Heading>}
            <p>{ReactHtmlParser(description)}</p>
        </div>
    );
}

export default InfoCard;

import React from "react";
import ReactHtmlParser from 'react-html-parser'; 

function AdvisorMember({advisorMember}) {
  return (
    <>
      <div className={`col-12 col-sm-6 col-md-4 col-lg-4 mb80`}>
        <div className="teamMember">
          <div className={`avatar border${advisorMember.borderType}`}>
            <img src={`${advisorMember.image}`} className="img-fluid" />
          </div>
          <h4>{advisorMember.name_bold_text}</h4>
          <h5>
            {/* {ReactHtmlParser(teamMember.role)} */}
            <span>{advisorMember.colored_text}</span> {advisorMember.normal_text}
            {/* TODO make the first color yellow */}
          </h5>
          <p>
            {advisorMember.explain}
          </p>
        </div>
      </div>
    </>
  );
}

export default AdvisorMember;

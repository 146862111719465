import React, {useEffect, useState} from "react";
import ShareBlog from "../ShareBlog/ShareBlog"

function BlogViewItem({blog}) {
    console.log(blog)
    return (
        <div key={blog.slug} className="postPreview">
            <div onClick={() => window.open("https://www.ftwdao.com/blog/" + blog.slug)}>
                <img className="postImage" src={blog.featured_image} alt=""/>
                <span className="postAuthor"><img src={blog.author.profile_image} alt=""/></span>

                <div className="content">
                    <h4 className="blog-overflow-header">{blog.title}</h4>
                    <p className="blog-overflow-text">{blog.meta_description}</p>
                </div>
            </div>

            <div className="postContent">
                <span className="date">
                  {(new Date(blog.published)).toLocaleDateString("en-US", {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                  })}
                </span>

                {/* https://www.ftwdao.com/blog/how-to-become-a-founding-member */}
                <ShareBlog url={"www.ftwdao.com/blog/" + blog.slug}></ShareBlog>
            </div>
        </div>
    );
}

export default BlogViewItem;

import React, { useContext, useEffect, useState } from "react";
import FAQs from "../../components/FAQs/FAQs";
import WinningValues from "../../components/WinningValues/WinningValues";
import { Accordion } from "react-bootstrap";
import { WalletContext } from "../../components/context/web3.context";
import cogoToast from "cogo-toast";
import Spinner from "../../components/Spinner/Spinner";
import { SpinnerCircular } from "spinners-react";
import "./NftProject.style.scss";
import { NavLink } from "react-router-dom";
import { AxiosInstance } from "../../AxiosRequest";

const winningValuesData = [
  {
    classes: "yellowBG",
    heading: "Diverse",
    body: "Diversity creates better returns for investors and society, fosters creativity, innovation, as well as better design and decision-making. DAOversity, For The Win.",
  },

  {
    heading: "Global",
    body: "Talent knows know borders. We’re global citizens, and aim to work together with people and founders wherever they’re based.",
  },

  {
    classes: "yellowBG",
    heading: "Long-term view",
    body: "Pressure to bend to the short-term interests of investors often creates toxic environments in start-ups. We're developing an ecosystem of big-picture thinkers and investors.",
  },

  {
    heading: "Inclusive",
    body: "We aim to build a movement that offers a safe space for people to be themselves and thrive knowing that we will celebrate and adapt to their individual strengths, talents and daily realities.",
  },

  {
    classes: "yellowBG",
    heading: "Bold",
    body: "FTW is here to make a dent in the universe. Diverse teams are a $trillion opportunity. We won’t rest until diversity is the default for founding teams and fund managers.",
  },

  {
    heading: "Mental wellness",
    body: "Every elite sportsperson has a coach. So should founders. We aim to foster founders' leadership skills and create an open dialogue on mental health to prevent founder burn-out and build great team cultures",
  },
];

const faqsData = [
  {
    Id: "0",
    header:
      "How many NFTs will be sold?",
    body: `Our full generative collection will be composed of up to 10,000 generative PFP NFTs that we will release in collaboration with artists from around the globe. <br /> <br /> Prior to the launch of the full collection, we are growing our early community by making available 100 OG Supporter NFTs, and 500 Early Supporter NFTs, which you can purchase when we launch in June.`,
    show: "true",
  },

  {
    Id: "1",
    header: "Can OG NFTs be purchased by an organisation or DAO?",
    body: "If your DAO or organisation is aligned with our mission and wants to showcase its support for FTW's mission to DAOversify the venture ecosystem, we will be happy to welcome you. <br /> <br />  Access and membership rights associated with the mint pass NFT can only be conferred to the wallet that is holding the NFT, and rights or benefits conferred by the mint pass can not be split or shared between multiple individuals. You are welcome to display the NFT you hold on your organisation's website, though some conditions apply. Please refer to the terms & conditions of sale,and the Mint Pass NFT license details. ",    
    show: "false",
  },

  {
    Id: "2",
    header: "What will the FTW's NFT sale achieve?",
    body: "Our goal is to raise a total of $2 million for the DAO treasury in 2022 via NFT sales.  <br /> <br /> This treasury will be an endowment fund governed by our DAO members and allocated to projects that further our mission and allow us to grow our ecosystem and funding over time. <br /> <br /> Our goal for the next 5 years is to develop the ecosystem into a $ billion dollar funding pool* that enables our community to support research, programs and for-profit projects that will find, fund and foster diverse founding teams globally.  <br /> <br />   * The term fund/funding pool does not refer to a collective investment scheme in the sense of the Collective Investment Schemes Act (CISA) <br /> <br /> In choosing to launch the DAO with NFTs as membership cards, we felt strongly about using the emotional and visual elements of art to build our community around the values and vibe of the community we want to foster. ",    
    show: "false",
  },

  {
    Id: "3",
    header: "When I purchase an OG NFT, what do I get?",
    body: "Benefits for the first 100 OG Supporter NFT holders include the following: <br /> <br /> <ul><li>OG Status (call yourself an OG on Linkedin and Twitter)</li><li>Once we sell out the main NFT collection, OGs receive 'Angel Tier' membership in the DAO</li><li>OGs have the option to be featured on our website</li><li>Priority and beta access for use of products and services FTW.DAO or our partners might launch</li><li>Chance to win a personalised 1/1 NFT in the main NFT sale</li><li>Three free mints in the generative NFT sale</li><li>Reserve-list spots so you can mint additional NFTs in the generative NFT sale</li></ul><br /> Angel Tier Benefits: <br /> <br /> <ul><li>Governance rights in FTW.DAO</li><li>Education and talent program cohort priority waitlisting</li><li>Participation in Coordinape</li><li>Curated angel lounge to meet interesting thinkers, founders and investors around the world, who have been selected by the DAO team</li><li>Exclusive or early access to future research, events or opportunities with FTW or partners working on diversity, web3, VC and DAOs</li></ul> <br /> <br /> To learn more about all the benefits, visit our <a href='#'> NFT Project page. </a>",    
    show: "false",
  },

  {
    Id: "4",
    header: "When I purchase an Early Supporter NFT, what do I get?",
    body: "Benefits for the first 500 Early Supporter NFT holders include the following: <br /> <br /> <ul><li>Early Supporter Status (call yourself an Early Supporter on Linkedin)</li><li>Once we sell out the main NFT collection, ESs receive full membership in the DAO</li><li>One free mint in the generative FTW People NFT sale</li><li>Reserve-list spot for additional mints in the main NFT sale</li></ul><br /> FTW People Benefits: <br /> <br /> <ul><li>Governance rights in FTW.DAO</li><li>Education and talent program cohort priority wait-listing straight after OGs</li><li>Get involved as a full member of FTW DAO projects and have access to all project channels, coordinape rounds and bounties</li><li>Early access to future research, events or opportunities with FTW or partners working on diversity, web3, VC and DAOs</li></ul> <br /> <br /> To learn more about all the benefits, visit our <a href='#'> NFT Project page. </a>",
    show: "false",
  },

  {
    Id: "5",
    header: "How many NFTs can I mint?",
    body: "We want to make our community as diverse and rich in bold, interesting ideas as we possibly can. This means we want to allow as many people as possible to get our mint passes at a fair price. <br /> <br /> On the flip side, we also know that many who discover our mission and roadmap will want to encourage their friends, family and colleagues to join the crew, and that many will want to mint an extra NFT or two as meaningful gifts. <br /> <br /> To balance these needs, each wallet can mint a maximum of 2 OG Mint Passes, as well as up to 2 Early Supporter Mint Passes. <br /> <br /> We have also reserved a portion of the both types of mint passes for scholarships that will be awarded to mission-aligned people who want to join the cause and contribute to the DAO.",
    show: "false",
  },

  {
    Id: "6",
    header:
      "How can I set-up my Metamask wallet to mint an NFT?",
    body: "To purchase your mintpass, you first need to set up a Metamask wallet. You will need to have a Metamask supported brwoser on your computer. MetaMask supports Firefox, Google Chrome, and Chromium-based browsers. <br /> <br /> Go to <a href='https://metamask.io/' target='_blank'> metamask.io </a> <br /> <br /><ol><li>Click 'Download'</li><li>Click 'Install Metamask for Chrome'. Once installed you will be able to click the puzzle piece icon in the top right of your browser (next to your profile pic).</li><li>Click 'Get started'. For the purposes of this guide we'll assume you don't have a wallet. Click 'Create a wallet'.</li><li>Create a strong password according to Metamask's guidelines.</li><li>After you create a password you will receive what's called a 'Secret Recovery Phrase.' This is a critical step as your 'Secret Recovery Phrase' is how you'll be able to restore your wallet if you ever lose access to your device. We recommend writing down the 12 words on a piece of paper and storing it in a secure location. <strong>Do not ever share your secret recovery phrase with anyone.</strong> Having multiple backups stored in secure locations is recommended.</li><li>Confirm your secret recovery phrase by clicking the words in the correct order then click 'Confirm'</li><li>Click 'All done'</li></ol> Note: Never share your seed phrase with anyone. Not even someone claiming to be providing technical support. If you are new to Web3, please educate yourself on using Web3 'hot wallets' and security best practices.",
    show: "false",
  },

  {
    Id: "7",
    header:
      "What is the NFT price in the public sale?",
    body: `OG Supporter NFT - 3 ETH (limited to 100) <br /> <br /> Early Supporter NFT - 0.33 ETH (limited to 500)`,
    show: "false",
  },

  {
    Id: "8",
    header:
      "Why did you choose Samy Halim as the mint pass artist?",
    body: `Our team spent considerable time looking for the right artist to transmit the values we stand for as a DAO. We were aiming to find an artist whose style could transmit what our DAO was about. We aimed to have the mint passes embody our mission to be a place where people had fun, and where every single person and their unique personalities and styles were celebrated. We aimed to transmit that we are people who are positive and optimistic about the future. Much like the 1920’s we see the 2020s as a time of great societal change and optimism to reshape the future into one we all want to live and work in. For this reason, we were looking for a style with references to the art deco movement.    <br /> <br /> In Samy we found the ideal artist for our mint passes. Born in Algeria to an Algerian father and French mother, Samy trained at the Fine Arts School of Algiers. He moved to France in 1994 where he worked as graphic designer and art director in Paris based studios and agencies for 15 years, before starting his own studio in 2011. <br /> <br /> Samy’s unique work in his own words: “my work is mainly about people and I like to show the beauty and personality in every single person. I like to show the diversity, equity, equality, in a colorful and vibrant style, using expressions and postures that create some connection with the onlooker. This is very important for my work. Having a hook and a unique look is key to successful artwork. I’m mostly inspired by the Art Déco era in the 20s-30s and the Memphis design of the 80s-90s.”`,
    show: "false",
  },

  {
    Id: "9",
    header:
      "What is the climate impact of buying a FTW NFT?",
    body: `We thought long and hard about which blockchain to use, and the trade-offs between ease of access and carbon emissions, and we ultimately chose to use Ethereum for the following reasons: <br /> <br /> <ul><li> The mission we are working towards will have a very strong positive impact on the climate mission. More diversity in companies' leadership has been shown to reduce companies' carbon emissions significantly. </li><li> Our ultimate goal to have a truly diverse DAO and membership, and empower more diverse entrepreneurs with tech, web3 and DAOs aims to have many thousands of times more positive impact for the climate. Our small carbon emission from the NFT project pales in comparison to the importance of achieving our goals </li><li> On-boarding diverse people to acquire our NFT and join our DAO is much easier if we start with the most widely used and owned asset in the NFT ecosystem. We want to avoid additional hurdles for new people joining us, participating in the DAO and supporting our mission. </li><li> To off-set our emissions from the NFT sale, we will use the Aerial platform, which allows NFT creators to off-set their emissions with verified off-set projects that can be seen on their website. This will be done for the whole FTW Mint Pass contract, once we sell-out the mint passes. Aerial's platform allows every NFT holder to check whether the off-set of their NFT has been done in an on-chain and verifibale manner.</li></ul>`,
    show: "false",
  },

  {
    Id: "10",
    header:
      "Can you tell us more about the generative NFT collection?",
    body: `The main NFT collection will feature the work of several upcoming artists from around the world in one beautiful generative collection. The collection will feature generative PFPs that represent the beautiful spectrum of humanity in a bold, colourful and fun way. <br /> <br /> Our generative collection artists and traits will be chosen with input from the community based on our existing style, aesthetic and values.<br /> <br /> Our goal with the generative NFT collection is to celebrate diversity in as truer way as possible, merging different styles, ideas and personalities into a cohesive, bold, fun and colourful collection. The collection aims to be one in which everyone can find a PFP they identify with on an individual and emotional level. <br /> <br /> If you are an experienced NFT artist or digital artist and share our vision for a DAOverse venture ecosystem, please reach out and say Hi via our contact form.`,
    show: "false",
  },

  {
    Id: "11",
    header:
      "Will Samy’s art also be featured in the main NFT collection?",
    body: `The main NFT collection will feature the work of several upcoming artists from around the world. Samy’s work on the mint pass was chosen to set the tone, and our main collection artists will be chosen with input from the community based on this style, aesthetic and mission.<br /> <br /> We are discussing options with Samy for his participation in the main collection. Depending on his availability, we expect to feature his work as rare 1/1 pieces within the main collection.`,
    show: "false",
  },
];

function NftProject(props) {
  const {
    contract,
    web3Provider,
    accountId,
    connectWallet,
    connectContract,
    nftPriceA,
    nftPriceB,
    walletLimit,
    supplyTotal,
    retrieveData,
    // paused,
    isSaleAActive,
    isSaleBActive,
    totalSupplyA,
    totalSupplyB,
    totalPassA,
    totalPassB,
    remainingPassA,
    remainingPassB,
    isWhitelist,
    walletLimitA,
    walletLimitB
  } = useContext(WalletContext);

  const [loading, setLoading] = useState(false);

  const commonMint = async (mintingType) => {
    try {

      // Checks whether the user is whitelisted
      let getAccountId = accountId;
      let web3Pro = web3Provider;
      let price = mintingType ? nftPriceA : nftPriceB;
      if (!accountId) {
        setLoading(true);
        const data  = await connectWallet();

        getAccountId = data.accountID;
        web3Pro = data.web3;
      }
      let getHash = [];
      if (isWhitelist && mintingType) {
        const isWhitelistUser = await AxiosInstance.getRequest(`/address/${getAccountId}`);
        if(!isWhitelistUser.available){
           return cogoToast.error("You are not a whitelist user");
        }
        getHash = isWhitelistUser.data.hexProof;
        price = web3Pro.utils.toWei((isWhitelistUser.data.price).toString(), "ether");
      }

      // Checks whether the sale of token A is active.
      if (mintingType && !isSaleAActive) {
        return cogoToast.error("The current sale for token type A is inactive");
      }

      // Checks whether the sale of token B is active.
      if (!mintingType && !isSaleBActive) {
        return cogoToast.error("The current sale for token type B is inactive");
      }

      setLoading(true);

      const getContract = await connectContract();

      // Gives the number of tokens claimed by the current user.
      let claimed, walletLimit;

      if (mintingType) {
        claimed = await getContract.methods.claimedA(getAccountId).call();
        walletLimit = walletLimitA;
      } else if (!mintingType) {
        claimed = await getContract.methods.claimedB(getAccountId).call();
        walletLimit = walletLimitB;
      }

      claimed = parseInt(claimed);

      // Only allow if claimed tokens are less than the wallet limit
      if (claimed < walletLimit) {

        // Mint token - Estimate gas before to avoid Metamask warning
        await getContract.methods.mint(1, mintingType, getHash).estimateGas({
          from: getAccountId,
          value: price,
        });
        const response = await getContract.methods.mint(1, mintingType, getHash).send({
          from: getAccountId,
          value: price,
        });

        // Updates the number of minted tokens
        await supplyTotal();
        setLoading(false);
        cogoToast.success("Minted successfully");
        console.log(response);
      } else {
        cogoToast.error("You have reached the maximum limit");
      }
    } catch (error) {
      console.log(error);
      cogoToast.error("Error: ", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const preMintRequirements = async () => {

      if (!contract) {
        // Gets the contract instance
        await connectContract();
      }

      if (contract) {
        // Gets the current state of the required data
        await retrieveData(contract);
      }
    };

    preMintRequirements();
  }, [contract]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="daoheroBanner">
              <div className="nfteclipse">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1023" height="481" viewBox="0 0 1023 481" fill="none">
                    <circle cx="893.5" cy="129.5" r="129.5" fill="#FDC307"/>
                    <circle cx="654" cy="395" r="86" fill="#FDC307"/>
                    <circle cx="173" cy="250" r="173" fill="#FDC307"/>
                  </svg>
              </div>
            <div className="container containerCustom">
              <div className="daoheroBannerContent">
                <h1>
                  Join us. DAOversify <br/> Venture Capital.
                </h1>
                <h2>
                  Minting is now live for early supporters.
                </h2>
                
              </div>
            </div>
          </div>

          <div className="middlePart mt-5">
            <div className="middleContent" id="mint-section">
              <div className="container containerCustom">
                <div className="nftMintPassPart">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-7">
                      <div className="passImg">
                        {/* <img src="images/ogSupporter.png" className="img-fluid" /> */}
                        <picture className="">
                            <source media="(min-width:992px)" srcset="images/ogSupporter_gold.png"></source>
                            <source media="(min-width:650px)" srcset="images/ogSupporter_gold2x.png"></source>
                            <source media="(min-width:320px)" srcset="images/ogSupporter_gold1x.png"></source>
                            <img src="images/ogSupporter_gold.png" className="img-fluid" />
                        </picture>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-5">
                      <h4>mint pass</h4>
                      <h2>OG Supporter</h2>
                      {!totalPassA ? (
                        <div className="mint-spinner">
                          <SpinnerCircular color="black"/>
                        </div>
                      ) : (
                        <>
                          <h5>{`${totalPassA - totalSupplyA} / ${totalPassA}`} available</h5>
                          <button
                            type="button"
                            className={`btn ${
                              remainingPassA ? "btnTheme" : "btnBlack"
                            } btnWidth`}
                            onClick={() => {
                              commonMint(true);
                            }}
                          >
                          {remainingPassA
                            ? `Mint reserved pass • ${
                                nftPriceA / 1000000000000000000
                              } ETH`
                            : "SOLD OUT!"}
                          </button>
                        </>
                      )}
                      <br /><br />
                      <a href="https://tally.so/r/mZj6jo" target="_blank" className="btn btnThemeGreen btnWidth" rel="noreferrer">
                        Apply for OG Membership
                      </a>
                      <p>
                        Founding Member status in FTW.DAO <br />
                        Play a key part in creating a better 2030 <br />
                        Access to global investment opportunities <br />
                        Hire FTW's talent to review opportunities <br />
                        Suggest projects for DAO grants funding <br />
                        'First 100' limited edition Membership NFT <br />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="nftMintPassPart">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-7">
                      <div className="passImg">
                        {/* <img src="images/earlySupporter.png" className="img-fluid" /> */}
                        <picture className="">
                            <source media="(min-width:992px)" srcset="images/earlySupporter_multi.png"></source>
                            <source media="(min-width:650px)" srcset="images/earlySupporter_multi2x.png"></source>
                            <source media="(min-width:320px)" srcset="images/earlySupporter_multi1x.png"></source>
                            <img src="images/earlySupporter_multi.png" className="img-fluid" />
                        </picture>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-5">
                      <h4>mint pass</h4>
                      <h2>Early Supporter</h2>
                      {!totalPassB ? (
                        <div className="mint-spinner">
                          <SpinnerCircular color="black"/>
                        </div>
                      ) : (
                        <>
                          <h5>{`${totalPassB - totalSupplyB} / ${totalPassB}`} available</h5>
                          <button
                            type="button"
                            className={`btn ${
                              remainingPassB ? "btnTheme" : "btnBlack"
                            } btnWidth`}
                            onClick={() => {
                              commonMint(false);
                            }}
                          >
                          {remainingPassB
                            ? `Mint now • ${
                                nftPriceB / 1000000000000000000} ETH`
                            : "SOLD OUT!"}
                          </button>
                        </>
                      )}
                      <br />
                      <p>
                       'Early Supporter' Member Status <br />
                       Be part of DAOversifying VC with us <br />
                       Priority access to FTW VC Talent Program <br />
                       Earn money or reputation while learning <br />
                       Exposure to FTW's investor network <br />
                       Have a say in DAO projects and funding <br />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="nftMintPassPart">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-7">
                      <div className="passImg">
                        {/* <img src="images/ftwPeeps.png" className="img-fluid" /> */}
                        <picture className="">
                            <source media="(min-width:992px)" srcset="images/ftwPeeps.png"></source>
                            <source media="(min-width:650px)" srcset="images/ftwPeeps2x.png"></source>
                            <source media="(min-width:320px)" srcset="images/ftwPeeps1x.png"></source>
                            <img src="images/ftwPeeps.png" className="img-fluid" />
                        </picture>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-5">
                      <h4>COMING IN 2025</h4>
                      <h2>FTW People</h2>
                      <h5>Generative PFP Collection</h5>
                      <p>
                        <strong>Free mint/s included with mint pass</strong><br />
                      Generative, inclusive PFP artwork <br />
                      Unique art and traits by global artists<br />
                      Launch planned for 2025<br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="middleBottom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1280"
                height="1700"
                viewBox="0 0 1280 1700"
                fill="none"
              >
                <g clipPath="url(#clip0_186_6)">
                  <rect width="1280" height="1700" fill="black" />
                  <circle cx="640" cy="100" r="1600" fill="white" />
                  <path
                    opacity="0.2"
                    d="M458.608 1378.39L501.251 1366.17L500.122 1362.23C498.955 1358.15 498.622 1354.43 499.124 1351.05C499.599 1347.58 501.142 1343.91 503.752 1340.02C506.363 1336.14 510.24 1331.55 515.385 1326.26C523.774 1317.58 529.284 1308.95 531.916 1300.36C534.639 1291.74 534.573 1282.45 531.719 1272.49L531.641 1272.22C528.501 1261.26 522.925 1252.57 514.912 1246.15C506.963 1239.61 497.237 1235.59 485.735 1234.08C474.297 1232.46 461.698 1233.62 447.936 1237.57C433.45 1241.72 421.747 1247.72 412.826 1255.57C403.906 1263.41 397.885 1272.49 394.763 1282.79C391.731 1293.06 391.746 1303.89 394.809 1315.25L395.12 1316.34L439.257 1303.69L438.985 1302.74C438.05 1298.8 438.079 1294.97 439.073 1291.25C440.066 1287.54 441.932 1284.31 444.673 1281.56C447.478 1278.7 451.053 1276.65 455.399 1275.4C459.654 1274.18 463.645 1273.92 467.372 1274.62C471.072 1275.22 474.217 1276.62 476.806 1278.82C479.486 1280.99 481.358 1283.93 482.422 1287.65L482.5 1287.92C483.615 1291.81 483.909 1295.4 483.382 1298.69C482.944 1301.95 481.453 1305.46 478.908 1309.23C476.362 1312.99 472.53 1317.57 467.411 1322.96C462.202 1328.37 458.383 1333.68 455.956 1338.88C453.502 1343.99 452.38 1349.31 452.592 1354.83C452.804 1360.36 454.199 1366.42 456.779 1373.03L458.608 1378.39ZM499.816 1438.59C506.969 1436.54 512.36 1432.45 515.99 1426.31C519.711 1420.15 520.586 1413.63 518.614 1406.75C516.667 1399.96 512.485 1394.93 506.065 1391.68C499.736 1388.4 492.996 1387.78 485.843 1389.83C478.69 1391.88 473.299 1395.98 469.669 1402.11C466.038 1408.25 465.196 1414.71 467.143 1421.5C469.115 1428.38 473.265 1433.46 479.594 1436.74C485.923 1440.02 492.664 1440.64 499.816 1438.59Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_186_6">
                    <rect width="1280" height="1700" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="middleLastPart blackBG">
              <div className="container containerCustom">
                <div className="nftArtistPart">
                  <div className="row nftArtistDetails">
                    <div className="col-12">
                      <h4>Mint Pass Artist</h4>
                      <h2>Samy Halim</h2>
                    </div>
                  </div>
                  <div className="row nftArtistDetails">
                    <div className="col-12 col-md-12 col-lg-7">
                      <p>
                        Born in Algeria and trained at the Fine Arts School of Algiers, Samy moved to France in 1994 and refined his graphic arts skills on 
                        digital tools such as Photoshop & Illustrator. 
                        <br />
                        <br />
                        Samy worked as graphic designer and art director in Paris based studios and agencies for 15 years, before starting his own studio in 2011. 
                        He was trained as a classical artist and after graduating, he explored numerous techniques and styles. He eventually ended up with this unique style which combines hand drawn and digital rendering.
                        <br />
                        <br />
                        Samy’s pop work is colorful, urban and bold. His polychrome and geometric style finds its inspiration in Art Deco and Memphis-Milano. 
                        He especially enjoys manipulating ultramarine color, gold foil, colored pencils texture and shapes.
                        <br />
                        <br />
                        Samy has previoulsy worked with many major brands such as Yves Saint Laurent, Rémy Martin, L'Oréal, Jean-Paul Gautier, 
                        Paul Smith and Ubisoft. He recently won <span><b> Artist of the Year at Stroke Art Fair in Munich  </b></span>
                      </p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                      <img src="images/artistPic2.jpg" className="img-fluid" />
                      {/* <picture className="">
                            <source media="(min-width:992px)" srcset="images/artistPic.png"></source>
                            <source media="(min-width:650px)" srcset="images/artistPic2x.png"></source>
                            <source media="(min-width:320px)" srcset="images/artistPic1x.png"></source>
                            <img src="images/artistPic.png" className="img-fluid" />
                        </picture> */}
                    </div>
                  </div>
                </div>

                <div className="nftValue">
                  <h2>FTWinning values</h2>
                  <div className="row sixBoxes">
                    {winningValuesData.map((data) => {
                      return (
                        <WinningValues
                          key={data.heading}
                          winningValuesData={data}
                        />
                      );
                    })}
                  </div>

                  <NavLink to="/roadmap" className="btn btnYellow btnWidth">
                    View our roadmap
                  </NavLink>
                </div>

                <div className="nftFAQPart">
                  <h2>Frequently Asked Questions</h2>

                  <Accordion defaultActiveKey="0" id="accordion">
                    {faqsData.map((data) => {
                      return <FAQs key={data.Id} faqsData={data} />;
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NftProject;

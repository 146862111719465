import "./App.css";
import "./temporary-css.scss";
import "./fonts.css";
import "./media.css";

import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Navigate, Route, Routes } from "react-router-dom";
import NftProject from "./pages/NftProject/NftProject";
import Roadmap from "./pages/Roadmap/Roadmap";
import Dao from "./pages/Dao/Dao";
import Team from "./pages/Team/Team";
import Login from "./components/admin/Login/Login";
import Dashboard from "./components/admin/Dashboard/Dashboard";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/Post";
import ScrollToTop from "./components/ScrollToTop";
import Membership from "./pages/Member/Member";

function App() {
  return (
    <div className="App">
      <ScrollToTop x= {0} y = {0}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dao" element={<Dao />} />
          <Route path="/nft" element={<NftProject />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/team" element={<Team />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:slug' element={<BlogPost />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;

/***
 *
 * web3Context.js
 * Created By : Codiste Pvt Ltd
 * Date : 1st March, 2022
 *
 ***/

// Import required Modules
import React, { Component } from "react";
import Web3 from "web3";
import cogoToast from "cogo-toast";
import { AxiosInstance } from "../../AxiosRequest";
import { RPC_URL, contractAddress, abi, NETWORK_ID } from "../../config/config";

// Context to store the state of the application
export const WalletContext = React.createContext();

class WalletContextProvider extends Component {
  constructor(props) {
    super(props);

    // Initial state of the app.
    this.state = {
      data: null,
      accountBalance: 0,
      web3Provider: null,
      web3RPCProvider: null,
      contract: null,
      accountId: null,
      balance: 0,
      act: false,
      loading: false,
      walletLimit: 0,
      nftPriceA: 0,
      nftPriceB: 0,
      claimed: 0,
      totalSupply: 0,
      isPreSale: 1,
      isMainSale: false,
      max_nft: 5000,
      percentage: 0,
      amount: 1,
      isWhiteListed: true,
      paused: false,
      isSaleAActive: false,
      isSaleBActive: false,
      totalSupplyA: 0,
      totalSupplyB: 0,
      totalPassA: 0,
      totalPassB:0,
      remainingPassA: 0,
      remainingPassB: 0,
      isWhitelist: false,
      walletLimitA: 0,
      walletLimitB: 0,
      currentNetwork: {
        chainId: NETWORK_ID,
        rpc_url: RPC_URL,
        chain_name: 'Rinkeby Testnet',
      }
    };
  }

  async componentDidMount() {
    let RPCProvider = new Web3(RPC_URL);
    // console.log("RPC = ", RPCProvider);
    this.setState(
      {
        web3RPCProvider: RPCProvider,
      }
    );
    if (window.ethereum) {
      // Triggers when the chain is changed
      window.ethereum.on("chainChanged", function (networkId) {
        localStorage.setItem("MetaMask", "");
        window.location.reload(true);
      });

      // Triggers when the account is changed
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          this.connectWallet();
        } else {
          console.log("Account disconnected");
        }
      });
    }
    // if (localStorage.getItem("MetaMask") === "true") {
    //   this.connectWallet();
    // }
  }

  // Checks whether the component need re renders by checking the previous state and current state.
  shouldComponentUpdate(nextState) {
    return nextState !== this.state;
  }

  // Connects to the wallet
  connectWallet = async (connectdata=this.state.currentNetwork) => {
    // Checks whether user has the metamask installed or not
    if (window.ethereum) {
      // Gets the web3 instance
      let web3 = new Web3(window.ethereum);
      try {
        // collects all the accounts
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const accountID = accounts[0];

        const networkId = await web3.eth.net.getId();

        // Checks the wallet is using the correct chain.
        if (connectdata.chainId === undefined || networkId === connectdata.chainId) {
          // const balance = await web3.eth.getBalance(accountID);
          // console.log(balance);
          // const accountBalance = web3.utils.fromWei(balance, "ether");
          // console.log("accountBalance", accountBalance);
          this.setState({
            // accountBalance: parseFloat(accountBalance).toFixed(4),
          });
          localStorage.setItem("MetaMask", "true");
          web3.eth.defaultAccount = accounts[0];
          this.setState({
            web3Provider: web3,
            accountId: accountID,
          });
        } else {
          let chainId = web3.utils.toHex(parseInt(connectdata.chainId));
          const response = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }]
          });
          console.log("response = ", response);
          const data = await this.connectWallet(connectdata);
          return data;
        }
        return {accountID, web3};
      } catch (error) {
        console.log(error);
        if (error.code === 4902) {
          try {
            let chainId = web3.utils.toHex(parseInt(connectdata.chainId))
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chainId,
                  rpcUrl: connectdata.rpc_url,
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
        cogoToast.error(error.message);
      }
    } else if (window.web3) {
      let web3 = new Web3(window.web3.currentProvider);
      this.setState({ web3Provider: web3 });
    } else {
      cogoToast.error("You have to install MetaMask !");
    }
  };

  // Connects to the contract in blockchain
  connectContract = async () => {
    let web3
    if(this.state.web3Provider)
    {
      web3 = new Web3(this.state.web3Provider);
    }else{
      web3 = new Web3(RPC_URL);
    }
    const contract = await new web3.eth.Contract(abi, contractAddress);
    this.setState(
      {
        contract: contract,
      },
      async () => {
        // this.checkData();
      }
    );

    return contract;

  };

  supplyTotal = async () => {
    try{
      const {contract} = this.state;
      const totalSupplyA = await contract.methods.totalSupplyA().call();
      const totalSupplyB = await contract.methods.totalSupplyB().call();

      this.setState({
        totalSupplyA,
        totalSupplyB
      });
    } catch(error) {
      console.log(error);
    }
  }

  retrieveData = async () => {
    try{
      const {contract} = this.state;
      console.error("ERRERERE", contract)
      const nftPriceA = await contract.methods.typeAnftPrice().call();
      const nftPriceB = await contract.methods.typeBnftPrice().call();
      console.error("got first", nftPriceA, nftPriceB,)

      const isWhitelist = await contract.methods.isWhitelist().call();

      const walletLimitA = await contract.methods.walletLimitA().call();
      const walletLimitB = await contract.methods.walletLimitB().call();
      const isSaleAActive = await contract.methods.isTypeASaleActive().call();
      const isSaleBActive = await contract.methods.isTypeBSaleActive().call();
      const totalSupplyA = await contract.methods.totalSupplyA().call();
      const totalSupplyB = await contract.methods.totalSupplyB().call();
      const totalPassA = await contract.methods.typeA().call();
      const totalPassB= await contract.methods.typeB().call();

      this.setState({
        nftPriceA: parseFloat(nftPriceA),
        nftPriceB: parseFloat(nftPriceB),
        isSaleAActive,
        isSaleBActive,
        isWhitelist,
        walletLimitA: parseInt(walletLimitA),
        walletLimitB: parseInt(walletLimitB),
        totalSupplyA: parseInt(totalSupplyA),
        totalSupplyB: parseInt(totalSupplyB),
        totalPassA: parseInt(totalPassA),
        totalPassB: parseInt(totalPassB),
        remainingPassA: totalPassA - totalSupplyA,
        remainingPassB: totalPassB - totalSupplyB,
      })
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <WalletContext.Provider
        value={{
          ...this,
          ...this.state,
        }}
      >
        {children}
      </WalletContext.Provider>
    );
  }
}

export default WalletContextProvider;

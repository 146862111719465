import React from 'react'
import { SpinnerDiamond } from 'spinners-react';
import './Spinner.style.scss';

function Spinner() {
  return (
    <div className="loader" color="#ffffff">
          <SpinnerDiamond size={150} color="black" />
    </div>
  )
}

export default Spinner;
import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import TeamMember from "../../components/TeamMember/TeamMember";
import AdvisorMember from "../../components/TeamMember/AdvisorMember";
import WinningValues from "../../components/WinningValues/WinningValues";
import PartnerCard from "../../components/PartnerCard/PartnerCard";
import butterClient from "../../butter-client";

function Team() {

    const [pageData, setPageData] = useState();
    const [topBlogs, setTopBlogs] = useState();


    useEffect(() => {
        const fetchPageContent = async () => {
            const data = await butterClient.page.retrieve('*', 'team-page-v2')
            const fieldData = data.data.data.fields;
            setPageData(fieldData);
            console.log(fieldData);

        }

        fetchPageContent().then(res => {
            console.log(res)
        });
    }, [])

    if (pageData) {
        return (
            <div>
                <div className="daoheroBanner">
                    <div className="teamellipse">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="984"
                            height="259"
                            viewBox="0 0 984 259"
                            fill="none"
                        >
                            <circle cx="689.5" cy="129.5" r="129.5" fill="#FDC307"/>
                            <circle cx="86" cy="128" r="86" fill="#FDC307"/>
                            <circle cx="919.5" cy="159.5" r="64.5" fill="#FDC307"/>
                        </svg>
                    </div>
                    <div className="container containerCustom">
                        <div className="teamBannerContent">
                            <h1>{pageData.team_member_blocks.header}</h1>
                        </div>
                    </div>
                </div>
                <div className="middlePart">
                    <div className="middleContent">
                        <div className="container containerCustom">
                            <div className="teamPart">
                                <div className="row">
                                    {pageData.team_member_blocks.team_member.map((data) => {
                                        return <TeamMember key={data.name} teamMember={data}/>;
                                    })}
                                </div>
                            </div>

                            <div className="teamPart">
                                <h1 className="text-center">{pageData.advisor_member_blocks.header}</h1>
                                <div className="row">
                                    {pageData.advisor_member_blocks.advisor_member.map((data) => {
                                        return <AdvisorMember key={data.name} advisorMember={data}/>;
                                    })}
                                </div>

                                <div className="text-center">
                                    <h4 className="text-center mb-4">
                                        {pageData.text_button_component.header_question_one}
                                        <br/>
                                        {pageData.text_button_component.header_question_two}
                                    </h4>
                                    <NavLink to="/nft" className="btn btnTheme btnWidth">
                                        {pageData.text_button_component.button_text}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="middleLastPart blackBG posRelative">
                        <div className="middleLastTop">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1280"
                                height="140"
                                viewBox="0 0 1280 140"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_194_2)">
                                    <rect width="1280" height="140" fill="white"/>
                                    <circle cx="640" cy="1600" r="1600" fill="black"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_194_2">
                                        <rect width="1280" height="140" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="container containerCustom">
                            <div className="nftValue">
                                <h2>{pageData.six_block_aim_component.header}</h2>
                                <div className="row sixBoxes">
                                    {pageData.six_block_aim_component.block.map((data) => {
                                        return (
                                            <WinningValues
                                                key={data.header}
                                                winningValuesData={data}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="row">
                                    <NavLink to="/dao"
                                             className="btn btnYellow btnWidth mr-5">{pageData.six_block_aim_component.first_button_text}</NavLink>
                                    <a href="mailto:hello@forthewin.ventures"
                                       className="btn btntransparentBorderedWhite">{pageData.six_block_aim_component.second_button_text}</a>
                                </div>
                            </div>
                            <div className="ourPartnerPart">
                                <h2>Our partners</h2>
                                <div className="row">
                                    {pageData.partners_block_component.partner.map((data) => {
                                        return (
                                            <div
                                                key={data.heading}
                                                className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3"
                                            >
                                                <div className="partnerDetails text-center">
                                                    <PartnerCard infoCardData={data} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <> </>
    }
}

export default Team;
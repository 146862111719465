import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import { useContext, useEffect, useState } from "react";
import { AxiosInstance } from "../../../AxiosRequest";
import "./Dashboard.styles.scss";
import { WalletContext } from "../../context/web3.context";
import Spinner from "../../Spinner/Spinner";

const schema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  // addPrice: Yup.number().required("Price is required"),
  // removePrice: Yup.number().required("Price is required")
});

const Dashboard = () => {
  const { web3Provider, connectWallet, contract, connectContract, accountId } =
    useContext(WalletContext);

  const [pausedMinting, setPausedMinting] = useState(true);
  const [isSaleAActive, setIsSaleAActive] = useState(true);
  const [isSaleBActive, setIsSaleBActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [whitelistStatus, setWhitelistStatus] = useState(false);
  const [contractOwner, setContractOwner] = useState(null);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("ftx-token");
    navigate("/admin");
  };

  useEffect(() => {
    // Redirects to the login page if the user has not logged in
    if (!localStorage.getItem("ftx-token")) navigate("/admin");

    if (!web3Provider) {
      connectWallet();
    }

    if (!contract && web3Provider) {
      connectContract();
    }
  }, [web3Provider]);

  useEffect(() => {
    const getData = async () => {
      try{
        
        const saleA = await contract.methods.isTypeASaleActive().call();
        const saleB = await contract.methods.isTypeBSaleActive().call();
        const contractOwner = await contract.methods.owner().call();
        const whitelistStatus = await contract.methods.isWhitelist().call();

        setWhitelistStatus(whitelistStatus);
        setIsSaleAActive(saleA);
        setIsSaleBActive(saleB);
        setContractOwner(contractOwner.toLowerCase());
      } catch(err) {
        console.log("err", err);
      }
    };

    if (contract) {
      getData();
    }
  }, [contract]);

  useEffect(() => {

    // Retrieves the status of whitelist
    const getWhitelistStatus = async () => {
      const data = await AxiosInstance.getRequest('/user/getWhitelistStatus');
      setWhitelistStatus(data.data.whitelistStatus ? data.data.whitelistStatus : false);
    };

    getWhitelistStatus();
  }, []);

  const commonErrorMessage = () => {
    cogoToast.error("Something wrong happened");
  };

  const handleWhitelist = async () =>{
    try{
      setLoading(true);
      await contract.methods.changeWhitelistStatus(!whitelistStatus).send({
        from: accountId
      });

      setWhitelistStatus(!whitelistStatus);
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handlePrice = async (e) => {
    e.preventDefault();

    // Checks which token option is selected
    const tokenASelected = e.target["tokenA"].checked;
    const tokenBSelected = e.target["tokenB"].checked;

    try {
      let value = parseFloat(e.target["price"].value);

      if (isNaN(value)) return cogoToast.error("Please enter a valid number");
      if (tokenASelected) {
        setLoading(true);

        value = web3Provider.utils.toWei(value.toString(), "ether");

        const response = await contract.methods.setPriceA(value).send({
          from: accountId,
        });

        cogoToast.success("Price for token A is changed successfully");
        console.log(response);
      } else if (tokenBSelected) {
        setLoading(true);

        value = web3Provider.utils.toWei(value.toString(), "ether");

        const response = await contract.methods.setPriceB(value).send({
          from: accountId,
        });

        cogoToast.success("Price for token B is changed successfully");
        console.log(response);
      } else {
        cogoToast.error("Please Select any token");
      }
    } catch (error) {
      console.log(error);
      commonErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleWalletLimit = async (e) => {
    e.preventDefault();

    // Checks which token option is selected
    const tokenASelected = e.target["passA"].checked;
    const tokenBSelected = e.target["passB"].checked;

    try {
      let value = parseInt(e.target["setWalletLimit"].value);

      if (isNaN(value)) return cogoToast.error("Please enter a valid number");

      if(tokenASelected) {
        setLoading(true);

        const response = await contract.methods.setWalletLimitA(value).send({
          from: accountId,
        });

        cogoToast.success("Wallet limit for pass A is changed successfully");
        console.log(response);
      } else if (tokenBSelected) {
        setLoading(true);

        const response = await contract.methods.setWalletLimitB(value).send({
          from: accountId,
        });

        cogoToast.success("Wallet limit for pass B is changed successfully");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      commonErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleEnablePass = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);

      const passA = e.target['passAStatus'].checked;
      const passB = e.target['passBStatus'].checked;

      const response = await contract.methods.enableSale(passA, passB).send({
        from: accountId
      })

      setIsSaleAActive(passA);
      setIsSaleBActive(passB);
      console.log("response = ", response);
    } catch (err) {
      console.log("err = ", err);
    } finally {
      setLoading(false);
    }
  }

  const handlePreMint = async (e) => {
    e.preventDefault();

    if (contractOwner !== accountId) {
      return cogoToast.error("You are not the owner");
    }

    // Checks which token option is selected
    const tokenASelected = e.target["preMintA"].checked;
    const tokenBSelected = e.target["preMintB"].checked;
    try{

      if(tokenASelected) {
        setLoading(true);

        const response = await contract.methods.preMintA().send({
          from: accountId,
        });

        cogoToast.success("Token A pre-minted successfully");
        console.log(response);
      } else if (tokenBSelected) {
        setLoading(true);

        const response = await contract.methods.preMintB().send({
          from: accountId,
        });

        cogoToast.success("Token B pre-minted successfully");
        console.log(response);
      }
    } catch (err) {
      console.log("err = ", err);
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <>
      {!web3Provider || loading ? (
        <Spinner />
      ) : (
        <div className="dashboard">
          <div className="container containerCustom mt-1 pt-5">
            <div className="row justify-content-center mt-5 pt-5">
              <div className="col-md-12 text-right mb-3">
                <button
                  className="btn btnBlack float-end"
                  onClick={() => logout()}
                >
                  Log Out
                </button>
              </div>
            </div>

            <div className="col-md-12 dashboardPart">
              <Formik
                initialValues={{
                  address: "",
                  addPrice: null
                }}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  try {
                      if(contractOwner !== accountId) {
                        console.log("contract owner = ", contractOwner, " account id = ", accountId);
                        return cogoToast.error("You are not the owner");
                      }
                      console.log(localStorage.getItem('ftx-token'));
                      setLoading(true);
                      values.address = values.address.replaceAll(" ", "");
                      values.address = values.address.replaceAll("\n", "");
                      values.addPrice = parseFloat(values.addPrice);
                      console.log("values = ", values);
                      const data = await AxiosInstance.postPostRequest(
                        "/address",
                        {
                          address: values.address,
                          price: values.addPrice
                        },
                        "post"
                      );
                    cogoToast.success(data.message);
                    values.addPrice = web3Provider.utils.toWei((values.addPrice).toString(), 'ether');
                    const merkleRoot = await contract.methods.updateMerkleRoot(data.data.rootHash, values.addPrice).send({
                      from: accountId
                    });
                    setLoading(false);
                    setSubmitting(false);
                    resetForm();
                  } catch (error) {
                      console.log(error);
                    if (error.login) {
                      localStorage.removeItem("token");
                      return navigate("/admin");
                    }
                    setErrors({
                      address: "Something went wrong.",
                    });
                    setSubmitting(false);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, isSubmitting }) => (
                  <Form className="pt-3 row">
                    <div className="form-group col-8">
                      <label className="dashboard-label">
                        Add Addresses (Put all address with comma seperate)
                      </label>
                      <div className="input-group">
                        <textarea
                          type="text"
                          className="form-control form-control-lg border-left-0"
                          placeholder="e.g: 0x5B38Da6a701c568545dCfcB03FcB875f56beddC4,0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        ></textarea>
                        {errors.address && touched.address ? (
                          <div className="invalid-feedback d-block">
                            {errors.address}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col">
                      <label className="dashboard-label">
                        Price
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control form-control-lg border-left-0"
                          placeholder="Price"
                          name="addPrice"
                          value={values.addPrice}
                          onChange={handleChange}
                          required
                        ></input>
                        {errors.addPrice && touched.addPrice ? (
                          <div className="invalid-feedback d-block">
                            {errors.addPrice}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-5 col-auto">
                      <button
                        disabled={isSubmitting}
                        className="btn btnTheme"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="col-md-12 dashboardPart">
              <Formik
                initialValues={{
                  address: "",
                  removePrice: null
                }}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (
                  values,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  try {
                      console.log(localStorage.getItem('ftx-token'));
                      console.log("contract owner = ", contractOwner, " account id = ", accountId);
                      if(contractOwner !== accountId) {
                        return cogoToast.error("You are not the owner");
                      }
                      setLoading(true);
                      values.address = values.address.replaceAll(" ", "");
                      values.address = values.address.replaceAll("\n", "");
                      values.removePrice = parseFloat(values.removePrice);
                      const data = await AxiosInstance.postPostRequest(
                        "/removeAddress",
                        {
                          address:values.address,
                          price: values.removePrice
                        },
                        "post"
                      );
                    cogoToast.success(data.message);
                    values.removePrice = web3Provider.utils.toWei((values.removePrice).toString(), 'ether');
                    const merkleRoot = await contract.methods.updateMerkleRoot(data.data.rootHash, values.removePrice).send({
                      from: accountId
                    });
                    setLoading(false);
                    // console.log("Remove merkle = ", merkleRoot);
                    setSubmitting(false);
                    resetForm();
                  } catch (error) {
                      console.log(error);
                    if (error.login) {
                      localStorage.removeItem("token");
                      return navigate("/admin");
                    }
                    setErrors({
                      address: "Something went wrong.",
                    });
                    setSubmitting(false);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, isSubmitting }) => (
                  <Form className="pt-3 row">
                    <div className="form-group col-8">
                      <label className="dashboard-label">
                        Remove Addresses (Put all address with comma seperate)
                      </label>
                      <div className="input-group">
                        <textarea
                          type="text"
                          className="form-control form-control-lg border-left-0"
                          placeholder="e.g: 0x5B38Da6a701c568545dCfcB03FcB875f56beddC4,0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        ></textarea>
                        {errors.address && touched.address ? (
                          <div className="invalid-feedback d-block">
                            {errors.address}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col">
                      <label className="dashboard-label">
                        Price
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control form-control-lg border-left-0"
                          placeholder="Price"
                          name="removePrice"
                          value={values.removePrice}
                          onChange={handleChange}
                          required
                        ></input>
                        {errors.removePrice && touched.removePrice ? (
                          <div className="invalid-feedback d-block">
                            {errors.removePrice}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-5 col-auto">
                      <button
                        disabled={isSubmitting}
                        className="btn btnTheme"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="col-md-12 dashboardPart">
              <form className="row" onSubmit={handlePrice}>
                <div className="form-group col">
                  <label className="dashboard-label">Set Price</label>
                  <div className="row">
                    <div className="col-auto">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="tokenA"
                          value="option1"
                        ></input>
                        <label className="form-check-label" htmlFor="inlineRadio1">
                          Token A
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="tokenB"
                          value="option2"
                        ></input>
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          Token B
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          name="price"
                          placeholder="Price"
                          id="price"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 col-auto">
                  <button 
                    className="btn btnTheme font-weight-medium"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-12 dashboardPart">
            <form className="row" onSubmit={handleWalletLimit}>
              <div className="form-group col">
                <label className="dashboard-label">Set Maximum pass</label>
                <div className="row">
                  <div className="col-auto">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="passA"
                        value="option1"
                      ></input>
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Token A
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="passB"
                        value="option2"
                      ></input>
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        Token B
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        placeholder="Maximum pass per wallet"
                        name="setWalletLimit"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 col-auto">
                <button 
                  className="btn btnTheme font-weight-medium"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            </div>

            <div className="col-md-12 dashboardPart">
            <form className="row" onSubmit={handleEnablePass}>
              <div className="form-group col">
                <label className="dashboard-label">Enable Pass</label>
                <div className="row">
                  <div className="col-auto">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="passAStatus"
                        id="passAStatus"
                        checked={isSaleAActive}
                        onClick={() => setIsSaleAActive(!isSaleAActive)}
                      ></input>
                      <label className="form-check-label" htmlFor="passAStatus">
                        Pass A
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="passBStatus"
                        id="passBStatus"
                        checked={isSaleBActive}
                        onClick={() => setIsSaleBActive(!isSaleBActive)}
                      ></input>
                      <label className="form-check-label" htmlFor="passBStatus">
                        Pass B
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 col-auto">
                <button 
                  className="btn btnTheme font-weight-medium"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            </div>

            <div className="col-md-12 dashboardPart">
            <form className="row" onSubmit={handlePreMint}>
              <div className="form-group col">
                <label className="dashboard-label">Pre Mint</label>
                <div className="row">
                  <div className="col-auto">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="preMint"
                        id="preMintA"
                        value="option1"
                      ></input>
                      <label className="form-check-label" htmlFor="preMintA">
                        Token A
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="preMint"
                        id="preMintB"
                        value="option2"
                      ></input>
                      <label className="form-check-label" htmlFor="preMintB">
                        Token B
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 col-auto">
                <button 
                  className="btn btnTheme font-weight-medium"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            </div>

            <div className="dashboardButtonspart">
            <button className="btn btnTheme font-weight-medium" type="button" onClick={handleWhitelist}>
              { whitelistStatus ? "Disable Whitelist" : "Enable Whitelist"}
            </button>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;

import React, { Component, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import butter from '../../butter-client'

function Post() {
  const { slug } = useParams();
  const [ post, setPost ] = useState({});

  async function getPost(slug) {
    const fetchedPost = await butter.post.retrieve(slug)
    setPost(fetchedPost.data.data)
    console.log(fetchedPost.data.data)
  }

  useEffect(() => {
    getPost(slug)
  }, [])

  return (
    <div>

      <div className="middlePart">
        <div className="middleContent">
          <div className="container containerCustom postContent">

            {post?.title ? (
              <>
                <img className="featuredImage" src={post.featured_image} alt={post.featured_image_alt} />
                <h1>{post.title}</h1>
                <p className="postCredit">
                  {post.author.first_name} {post.author.last_name}
                  <span>  •  </span>
                  {(new Date(post.published)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
                </p>
                <div className="postBody" dangerouslySetInnerHTML={{__html: post.body}} />
              </>
            ) : ''}

          </div>
        </div>
      </div>

    </div>
  )
}


export default Post
